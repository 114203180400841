<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "@/Axiosconfig.js";
import Swal from "sweetalert2";
import Receipt from "@/views/pages/pos/printReceipt.vue";
import reportdet from "@/components/report/header";
import AddExpense from "@/views/pages/expenses/parts/AddExpense.vue"; // Import the AddExpense component

export default {
  page: {
    title: "Expenses",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Receipt,
    reportdet,
    AddExpense, // Add AddExpense component to the components section
  },
  data() {
    return {
      title: "Expenses",
      items: [
        {
          text: "Expenses",
        },
        {
          text: "Expense List",
          active: true,
        },
      ],
      headers: null,
      uniqueCars: null,
      showme: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [1, 5, 10, 25, 50, 100, 500, 1000, 1500, 2000],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      expenses: [], // Change variable name to expenses
      fromdate: "",
      todate: "",
      limit: 12,
      offset: 0,
      business: null,
      fields: [
        { key: "id", label: "#", sortable: true },
        { key: "reference_no", label: "Ref. No", sortable: true },
        { key: "category_name", label: "Category", sortable: true }, // Add category field
        { key: "total_amount", label: "Total Amount", sortable: true },
        { key: "date_added", label: "Date Added", sortable: true },
        { key: "expense_note", label: "Note", sortable: true },
        { key: "is_refund", label: "Is Refund", sortable: true },
        { key: "action", label: "Action", sortable: false },
      ],
    };
  },
  computed: {
    rows() {
      return this.expenses.length;
    },
  },
  mounted() {
    this.business = JSON.parse(sessionStorage.business_location);
    console.log(this.business);
    this.updatearrays();
    this.totalRows = this.expenses.length;
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatearrays() {
      Swal.fire({
        title: "Please Wait !",
        html: "Loading data...",
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .get(
          `expenses/?limit=${this.limit}&offset=${this.offset}&location=${this.business.location_id}&fromdate=${this.fromdate}&todate=${this.todate}`
        )
        .then((response) => {
          this.expenses = response.data["results"];
          Swal.close();
        })
        .catch((e) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "" + e,
            showConfirmButton: true,
          }).then((e) => {
            Swal.close(e);
          });
        });
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.limit = this.perPage;
      this.offset = (this.currentPage - 1) * this.perPage;
      this.updatearrays();
    },
    printpdf(pl) {
      this.pl = pl;
      const data = this.expenses.map((row) => ({
        "Ref No.": row.reference_no,
        Category: row.category_name,
        "Total Amount": row.total_amount,
        "Date Added": row.date_added,
        Note: row.expense_note,
        "Is Refund": row.is_refund ? "Yes" : "No",
      }));

      const headers = Object.keys(data[0]);
      const cars = [];
      Object.entries(data).forEach((val) => {
        const [key, value] = val;
        console.log(value)
        cars.push(Object.values(data[key]));
      });

      const uniqueCars = Array.from(new Set(cars));
      this.headers = headers;
      this.uniqueCars = uniqueCars;
    },
    getrpt() {
      const d = new Date();
      const year = d.getFullYear();
      const month = d.getMonth();
      const date = d.getDate();
      const hour = d.getHours();
      const min = d.getMinutes();
      const sec = d.getSeconds();
      const msec = d.getMilliseconds();
      const filename =
        year +
        "-" +
        month +
        "-" +
        date +
        "-" +
        hour +
        "-" +
        min +
        "-" +
        sec +
        "-" +
        msec;

      const data = this.expenses.map((row) => ({
        "Ref No.": row.reference_no,
        Category: row.category_name,
        "Total Amount": row.total_amount,
        "Date Added": row.date_added,
        Note: row.expense_note,
        "Is Refund": row.is_refund ? "Yes" : "No",
      }));

      const csvRows = [];
      const headers = Object.keys(data[0]);

      csvRows.push(headers.join(","));
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return '"' + escaped + '"';
        });
        csvRows.push(values.join(","));
      }
      const csvData = csvRows.join("\n");

      const blob = new Blob([csvData], { type: "textcsv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute(
        "download",
        this.title + filename + ".csv"
      );
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    deleterec(index, id, code) {
      Swal.fire({
        title: "Are you sure, you want to delete stock expense #" + code + "?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#000000",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("expenses/" + id + "/")
            .then(() => {
              this.saleslist.splice(index, 1);
              Swal.fire("Deleted!", code + " has been deleted.", "success");
            })
            .catch((e) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Error!",
                html: e,
                showConfirmButton: false,
                timer: 3000,
              });
            });
        }
      });
    },
    edit(data){
      console.log(data);
    },
    handleOk($event){
      // Emit a custom event to the child component
      this.$emit('confirmReturn');   
      console.log($event);
      this.$bvModsal.show('modal-expense');
      //$event.preventDefault();
    },
    viewReceipt(saleitem) {
      this.total = saleitem.grand_total;
      this.receiptNo = saleitem.code;
      this.paymentMethod = saleitem.paymethod;
      let items = [];
      saleitem.sales_items.forEach((i) => {
        items.push({
          product_title: i.product_title,
          retail_price: i.retail_price,
          quantity: i.qty,
        });
      });
      this.salesitems = items;
      this.$emit("printReceipt");
    },
  },
  middleware: "authentication",
};
</script>

<template>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <div class="row justify-content-between">
        <div class="col-sm-6">
          <div class="row justify-content-between">
            <div class="col-sm-3">
              <button
                type="button"
                class="btn btn-warning mb-3"
                v-b-modal.modal-expense
              >
                <i class="mdi mdi-plus me-1"></i> Add New
              </button>
            </div>
            <div class="col-sm-2">
              <button
                class="btn btn-secondary waves-effect waves-light uil-export"
                @click="getrpt()"
              >
                Export to CSV
              </button>
            </div>
            <div class="col-sm-2">
              <button
                @click="printpdf('p')"
                v-b-modal.modal-Print
                class="btn btn-secondary waves-effect waves-light uil-file"
              >
                Print PDF
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="float-end">
            <div class="form-inline mb-3">
              <div
                class="input-daterange input-group"
                data-provide="datepicker"
                data-date-format="dd M, yyyy"
                data-date-autoclose="true"
              >
                <div class="form-group">
                  <label for="adjustmentType">Type</label>
                  <select v-model="status" class="form-control">
                    <option value="increase">Increase</option>
                    <option value="decrease">Decrease</option>
                  </select>
                </div>
                <input
                  type="date"
                  class="form-control text-left"
                  placeholder="11/13/2023"
                  name="From"
                  v-model="fromdate"
                />
                <input
                  type="date"
                  class="form-control text-left"
                  placeholder="11/13/2023"
                  name="To"
                  v-model="todate"
                />
                <div class="input-group-append">
                  <button type="button" class="btn btn-primary" @click="updatearrays()">
                    <i class="mdi mdi-filter-variant"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center fw-normal">
              Show&nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                @input="handlePageChange(currentPage)"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-end">
            <label class="d-inline-flex align-items-center fw-normal">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ms-2"
              ></b-form-input>
            </label>
          </div>
        </div>
      </div>
      <div class="table-responsive mb-0">
        <b-table
          table-class="table table-centered datatable table-card-list"
          thead-tr-class="bg-transparent"
          :items="expenses"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:cell(id)="data">
            <div class="custom-control custom-checkbox">
                <b-form-checkbox v-model="data.item.id" :id="`expense_cat${data.item.id}`"></b-form-checkbox>
            </div>
          </template>
          <template v-slot:cell(is_refund)="data">
            <a href="javascript: void(0);" class="text-dark fw-bold">
              <div class="custom-control custom-checkbox text-left">
                <b-form-checkbox v-model="data.item.is_refund"></b-form-checkbox>
              </div>
              </a>
          </template>
          <template v-slot:cell(action)="data">
            <ul class="list-inline mb-0">
              <b-dropdown class="list-inline-item" variant="white" right toggle-class="text-muted font-size-18 px-2">
                <template v-slot:button-content>
                  <i class="uil uil-ellipsis-v"></i>
                </template>
                <a href="#" class="dropdown-item uil uil-eye">View</a>
                <a href="#" class="dropdown-item uil uil-edit" v-b-modal.modal-expense @click="edit(data.item)">Edit</a>
                <a href="#" class="dropdown-item uil uil-trash-alt" @click="deleterec(data.index,data.item.id,data.item.reference_no)">Delete</a>
                <a href="#" class="dropdown-item uil uil-print">Print</a>
              </b-dropdown>
            </ul>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-end">
            <ul class="pagination pagination-rounded">
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
      <b-modal
        id="modal-expense"
        ref="modal-expense"
        title="Add Expense"
        size="xl"
        class=""
        hide-footer
      >
        <AddExpense />
      </b-modal>
      <b-modal id="modal-receipt" ref="modal" title="Print Receipt" size="lg" class="">
        <Receipt
          :items="salesitems"
          :headers="receiptHeaders"
          :total="total"
          :paymentMethod="paymentMethod"
          :receiptNo="paycode"
          @printReceipt="viewReceipt"
        />
      </b-modal>
      <b-modal id="modal-Print" title="Print PDF" hide-footer size="bg" centered>
        <reportdet
          :title="title"
          :orderData="orderData"
          :pl="pl"
          :headers="headers"
          :uniqueCars="uniqueCars"
          :shome="showme"
          v-show="showme"
        ></reportdet>
      </b-modal>
    </Layout>
  </template>
  >
